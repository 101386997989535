#renderedCheckoutButtonHTML {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 258.96px;
    background: rgb(75, 111, 86);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
    border-radius: 10px;
    font-family: Domine, SQ Market, Helvetica, Arial, sans-serif;
}

.embedded-checkout-link-html-hidden-textarea,
.checkout-button-html-hidden-textarea {
    opacity: 0;
    position: absolute;
}
