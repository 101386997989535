@import "../node_modules/bootstrap/scss/bootstrap";


$theme-colors: (
    "primary": rgb(75, 111, 86),
    "secondary": rgb(253, 167, 124),
    "secondary-dark": #e24e03,
    "primary-dark": #3e5b47,
    "secondary-1": rgb(53, 55, 59),
    "secondary-2": rgb(60, 47, 82),
    "secondary-3": rgb(175, 162, 197),
    "secondary-4": rgb(228, 231, 232),
    "tertiary": #ce4b6c,
);

.App {
    text-align: center;
    background-color: map-get($map: $theme-colors, $key: "primary");
}

a {
    color: map-get($map: $theme-colors, $key: "secondary");
    text-decoration: underline;
}
a:hover {
    color: map-get($map: $theme-colors, $key: "secondary");
    text-decoration: underline;
}

p {
    font-size: calc(14px + 0.4vw);
}

span {
    font-size: calc(14px + 0.4vw);
}

h2 span {
    font-size: calc(18px + 0.8vw);
}

h1 span {
    font-size: calc(22px + 0.8vw);
}

h2 {
    font-size: calc(18px + 0.8vw);
}

h1 {
    font-size: calc(22px + 0.8vw);
}

.App-header {
    background-color: map-get($map: $theme-colors, $key: "primary");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: map-get($map: $theme-colors, $key: "secondary-4");
}

.bg-navbar {
    background-color: map-get($map: $theme-colors, $key: "primary-dark");
    padding: 0px;
    color: map-get($map: $theme-colors, $key: "secondary-4");
}

.donate-button {
    color: map-get($map: $theme-colors, $key: "secondary");
    font-size: larger;
    text-decoration: none;
}

.donate-button-active:hover {
    color: map-get($map: $theme-colors, $key: "tertiary");
    font-size: larger;
    text-decoration: none;
}

.donate-button-active {
    color: map-get($map: $theme-colors, $key: "tertiary");
    font-size: larger;
    // text-decoration: none;
}

.footer-fluid {
    background-color: map-get($map: $theme-colors, $key: "primary-dark");
    font-size: large;
    // width: 100%;
    height: 20em;
    min-height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: map-get($map: $theme-colors, $key: "secondary-4");
}

#tma-logo {
    justify-content: space-around;
  }

  .tma-card-group {
    display: flex;
    row-gap: 3em;
    flex-wrap: wrap;
    // column-gap: 6em;
    justify-content: space-around;
  }

  .tma-card {
    min-width: 20%;
    max-width: 12em;
    background-color: map-get($map: $theme-colors, $key: "secondary");
    justify-content: space-around;
  }
  
  #tma-logo {
    padding: 0 0 0 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    height: 48px;
}

#socials-bar {
    display: flex;
    justify-content: space-around;
    width: 20em;
    margin-left: auto;
    margin-right: auto;
}

.language-dropdown {
    color: map-get($map: $theme-colors, $key: "secondary");
}

/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
.editor-active {
    box-sizing: border-box;
    cursor: text;
    background: map-get($map: $theme-colors, $key: "primary");
}

.wyswig-block-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}
.wyswig-block-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
}


.editor-readonly {
    background: map-get($map: $theme-colors, $key: "primary");
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}
.input {
    height: 34px;
    width: 220px;
    padding: 0 12px;
    font-size: 15px;
    font-family: inherit;
    background-color: transparent;
    border: none;
    color: #ddd;
}

.input:focus {
    outline: none;
}

.input::placeholder {
    color: #aaa;
}

.inputInvalid {
    color: map-get($map: $theme-colors, $key: "secondary");
}

.link {
    color: map-get($map: $theme-colors, $key: "secondary");
    text-decoration: underline;
}

.options {
    margin-bottom: 20px;
}

.dark {
    background-color: map-get($map: $theme-colors, $key: "primary-dark");

}

.left-justify {
    text-align: left;
}

.right-justify {
    text-align: right;
}

.center-justify {
    text-align: center;
}

/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */
/* WYSWIG EDITOR STYLES */


#language-dropdown {
    color: map-get($map: $theme-colors, $key: "secondary");
}

.socials-link {
    color: map-get($map: $theme-colors, $key: "secondary");
}

.navbar-light .navbar-nav .nav-link {
    color: map-get($map: $theme-colors, $key: "secondary-4");
    font-size: larger;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: map-get($map: $theme-colors, $key: "secondary");
    font-size: larger;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link:focus {
    color: map-get($map: $theme-colors, $key: "secondary");
    font-size: larger;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link-active {
    color: map-get($map: $theme-colors, $key: "tertiary");
    font-size: larger;
}

.jumbotron-dark-fluid {
    background-color: map-get($map: $theme-colors, $key: "primary-dark");
    width: 100vw;
    padding-top: 3em;
    padding-bottom: 3em;
    width: 100vw;
}

.jumbotron-light-fluid {
    background-color: map-get($map: $theme-colors, $key: "primary-light");
    width: 100vw;
    padding-top: 3em;
    padding-bottom: 3em;
    width: 100vw;
}

.jumbotron-title {
    color: map-get($map: $theme-colors, $key: "secondary");
}
.jumbotron-subtitle {
    color: map-get($map: $theme-colors, $key: "secondary");
}

.contact-link {
    display: flex;
    flex-direction: column;
}

.contact-link {
    display: flex;
    flex-direction: column;
}

.contact-link {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1200px) {
    #tma-logo {
        height: 36px;
    }
}

@media only screen and (max-width: 576px) {
    #tma-logo {
        height: 24px;
    }
}

@media only screen and (max-width: 398px) {
    #tma-logo {
        height: 15px;
    }
}

body {
    margin: 0;
    font-family: "Libre Franklin", Lora, Georgia, "Times New Roman", Times;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: map-get($map: $theme-colors, $key: "primary");
}
